<template>
	<div id="app">
		<Side v-if="this.$route.meta.level != 1"></Side>
		<router-view v-else class="router-view"></router-view>
		<div v-if="this.$store.state.globalLoading" class="loading" @click="this.$store.state.globalLoading = false">
			<div>
				<span></span>
				<span></span>
				<span></span>
				<span></span>
			</div>
			<small>加载中...</small>
		</div>
	</div>
</template>

<script>
import store from "./store";
export default {
	data() {
		return {
			info: {},
			token: ''
		};
	},
	mounted() {
		this.info = store.state.user;
		this.token = store.state.token;
	}
};
</script>
<style lang="less" scoped>
.router-view {
	transform: translateY(0px);
	box-sizing: border-box;
	height: 100vh;
}

.loading {
	z-index: 99999;
	background-color: rgba(0, 0, 0, 0.3);
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 1em;
	color: #cd0404;


	>div {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: space-between;
		width: 2em;

		span {
			width: 0.3em;
			height: 1em;
			background-color: #cd0404;
		}

		span:nth-of-type(1) {
			animation: grow 1s -0.45s ease-in-out infinite;
		}

		span:nth-of-type(2) {
			animation: grow 1s -0.3s ease-in-out infinite;
		}

		span:nth-of-type(3) {
			animation: grow 1s -0.15s ease-in-out infinite;
		}

		span:nth-of-type(4) {
			animation: grow 1s ease-in-out infinite;
		}
	}

	@keyframes grow {

		0%,
		100% {
			transform: scaleY(1);
		}

		50% {
			transform: scaleY(2);
		}
	}
}
</style>
