<template>
    <div class="box">
        <el-aside :class="isCollapse ? 'hide' : 'show'">
            <div class="menu-top">
                <img src="https://img.bbj.asia/file/fa0e0e514670913f76b3b.png"
                    style="max-width:48px;max-height:48px;height:48px;" />
                <b v-show="!isCollapse">雷速辅助</b>
            </div>
            <el-menu class="el-menu-vertical-demo" text-color="#FFFFFF" active-text-color="#409EFF"
                background-color="#3d4248" :default-active="$route.path" :collapse="isCollapse" router>
                <el-submenu index="1" popper-class="popper" v-permission="{ allow: [1, 2] }">
                    <template slot="title">
                        <i class="el-icon-s-data"></i>
                        <span>数据统计</span>
                    </template>
                    <el-menu-item index="/">
                        <i class="el-icon-data-analysis"></i>
                        <span slot="title">仪表盘</span>
                    </el-menu-item>
                    <el-menu-item index="/record_ranking"><i class="el-icon-s-data"></i>
                        <span slot="title">发布量排行</span>
                    </el-menu-item>
                </el-submenu>

                <el-submenu index="2">
                    <template slot="title">
                        <i class="el-icon-office-building"></i>
                        <span>业务管理</span>
                    </template>
                    <el-menu-item index="/index"><i class="el-icon-document-copy"></i>
                        <span slot="title">模板管理</span>
                    </el-menu-item>
                    <el-menu-item index="/user" v-permission="{ allow: [1, 2] }"><i class="el-icon-user"></i>
                        <span slot="title">用户管理</span>
                    </el-menu-item>
                    <el-menu-item index="/device" v-permission="{ allow: [1, 2] }">
                        <i class="el-icon-cpu"></i>
                        <span slot="title">设备管理</span>
                    </el-menu-item>
                    <el-menu-item index="/device_online"><i class="el-icon-s-data"></i>
                        <span slot="title">设备活跃时间</span>
                    </el-menu-item>
                    <el-menu-item index="/record" v-permission="{ allow: [1, 2] }">
                        <i class="el-icon-s-help"></i>
                        <span slot="title">发布记录</span>
                    </el-menu-item>
                    <el-menu-item index="/free_record" v-permission="{ allow: [1, 2] }">
                        <i class="el-icon-tickets"></i>
                        <span slot="title">免费发布记录</span>
                    </el-menu-item>
                    <el-menu-item index="/price"><i class="el-icon-attract"></i>
                        <span slot="title">余额管理</span>
                    </el-menu-item>
                    <el-menu-item index="/withdrawal_record" v-permission="{ allow: [2] }"><i
                            class="el-icon-attract"></i>
                        <span slot="title">提现记录</span>
                    </el-menu-item>
                    <el-menu-item index="/variable" v-permission="{ allow: [1, 2] }">
                        <i class="el-icon-star-off"></i>
                        <span slot="title">变量管理</span>
                    </el-menu-item>
                    <el-menu-item index="/sensitive_word"><i class="el-icon-s-data"></i>
                        <span slot="title">敏感词管理</span>
                    </el-menu-item>
                </el-submenu>

                <el-submenu index="3" v-permission="{ allow: [1, 2] }">
                    <template slot="title">
                        <i class="el-icon-s-tools"></i>
                        <span>系统设置</span>
                    </template>
                    <el-menu-item index="/log">
                        <i class="el-icon-time"></i>
                        <span slot="title">日志管理</span>
                    </el-menu-item>
                    <el-menu-item index="/ip">
                        <i class="el-icon-time"></i>
                        <span slot="title">IP访问统计</span>
                    </el-menu-item>
                    <el-menu-item index="/notice">
                        <i class="el-icon-bell"></i>
                        <span slot="title">公告管理</span>
                    </el-menu-item>
                    <el-menu-item index="/help">
                        <i class="el-icon-help"></i>
                        <span slot="title">帮助文档</span>
                    </el-menu-item>
                    <el-menu-item index="/remote" v-permission="{ allow: [2] }">
                        <i class="el-icon-phone-outline"></i>
                        <span slot="title">远程管理</span>
                    </el-menu-item>
                    <el-menu-item index="/cache" v-permission="{ allow: [2] }">
                        <i class="el-icon-receiving"></i>
                        <span slot="title">缓存管理</span>
                    </el-menu-item>

                    <!-- <el-menu-item index="/recycle">
                        <i class="el-icon-delete-solid"></i>
                        <span slot="title">回收站</span>
                    </el-menu-item> -->
                    <el-menu-item index="/blacklist">
                        <i class="el-icon-s-release"></i>
                        <span slot="title">黑名单管理</span>
                    </el-menu-item>
                    <el-menu-item index="/routers" v-permission="{ allow: [2] }">
                        <i class="el-icon-paperclip"></i>
                        <span slot="title">路由管理</span>
                    </el-menu-item>
                    <el-menu-item index="/system_setting" v-permission="{ allow: [2] }">
                        <i class="el-icon-setting"></i>
                        <span slot="title">系统设置</span>
                    </el-menu-item>
                </el-submenu>
                <el-submenu index="4" v-permission="{ allow: [2] }">
                    <template slot="title">
                        <i class="el-icon-s-custom"></i>
                        <span slot="title">权限管理</span>
                    </template>
                    <el-menu-item index="/permittion" v-permission="{ allow: [2] }">
                        <i class="el-icon-s-check"></i>
                        <span slot="title">权限管理</span>
                    </el-menu-item>
                    <el-menu-item index="/role" v-permission="{ allow: [2] }"><i class="el-icon-user-solid"></i>
                        <span slot="title">角色管理</span>
                    </el-menu-item>
                    <el-menu-item index="/api_keys" v-permission="{ allow: [2] }">
                        <i class="el-icon-s-finance"></i>
                        <span slot="title">API Key管理</span>
                    </el-menu-item>
                    <el-menu-item index="/template_permittion">
                        <i class="el-icon-paperclip"></i>
                        <span slot="title">用户-模板权限</span>
                    </el-menu-item>
                    <el-menu-item index="/permittion2">
                        <i class="el-icon-paperclip"></i>
                        <span slot="title">用户-页面权限</span>
                    </el-menu-item>
                </el-submenu>
                <el-submenu index="5" v-permission="{ allow: [1, 2] }">
                    <template slot="title">
                        <i class="el-icon-upload"></i>
                        <span slot="title">版本更新</span>
                    </template>
                    <el-menu-item index="/script_update" v-permission="{ allow: [1, 2] }">
                        <i class="el-icon-upload2"></i>
                        <span slot="title">脚本更新</span>
                    </el-menu-item>
                    <el-menu-item index="/update" v-permission="{ allow: [1, 2] }">
                        <i class="el-icon-top"></i>
                        <span slot="title">更新管理</span>
                    </el-menu-item>
                </el-submenu>
                <el-submenu index="6" v-permission="{ allow: [1, 2] }">
                    <template slot="title">
                        <i class="el-icon-s-opportunity"></i>
                        <span slot="title">ChatGPT</span>
                    </template>
                    <el-menu-item index="/chatgpt" v-permission="{ allow: [2] }">
                        <i class="el-icon-paperclip"></i>
                        <span slot="title">chatgpt镜像管理</span>
                    </el-menu-item>
                </el-submenu>
            </el-menu>
        </el-aside>
        <div id="main">
            <el-header class="header">
                <div>
                    <i style="cursor: pointer;font-size: 20px;" :title="isCollapse ? '展开' : '折叠'"
                        @click="isCollapse = !isCollapse"
                        :class="isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'"></i>
                    <TagMenu class="tag-menu"></TagMenu>
                </div>
                <div>
                    <Role :role="info.role" style="margin:0px 10px;"></Role>
                    <el-dropdown placement="bottom" @command="handleCommand">
                        <span class="el-dropdown-link mflex avater-group">
                            <el-avatar size="small"
                                :src="info.avatar || 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'"></el-avatar>
                            <span>{{ info.username }}</span>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <router-link to="/me">
                                <el-dropdown-item icon="el-icon-user" command="info">个人信息</el-dropdown-item>
                            </router-link>
                            <router-link to="/setting">
                                <el-dropdown-item icon="el-icon-setting" command="setting">设置</el-dropdown-item>
                            </router-link>
                            <el-dropdown-item icon="el-icon-switch-button" command="logout">退出登录</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </el-header>
            <router-view id="view"></router-view>
            <el-backtop target="#main" :right="50" :bottom="50" :visibility-height="10"></el-backtop>
        </div>
    </div>
</template>
<script>
import { Success } from "@/common/common";
import store from "../store";
import TagMenu from "./TagMenu.vue";
import axios from "axios";

export default {
    name: "Side",
    components: { TagMenu },
    data() {
        return {
            isCollapse: true,
            info: {
                role: 0,
                username: "--",
                avater: ""
            }
        }
    },
    created() {
        this.info = store.state.user;
    }, methods: {
        logout() {
            this.$confirm('是否要退出登录？', "提示")
                .then(_ => {
                    Success("退出登录");
                    this.$store.commit('resetState');
                    this.$router.push("/login");
                    axios("/api/common/logout").then(() => {
                    })
                })
                .catch(_ => { });
        },
        handleCommand(c) {
            switch (c) {
                case "logout":
                    this.logout();
                    break;
                default: break;
            }
        }

    },
}
</script>

<style lang='less'>
.box {

    .el-submenu__title:focus,
    .el-submenu__title:hover {
        background-color: initial;
    }
}
</style>

<style lang="less" scoped>
@bgcolor: #3d4248;
@primary: #409EEF;


.box {
    height: 100vh;
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    box-sizing: border-box;

    .menu-top {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FFF;
        padding: 10px;
        user-select: none;
        cursor: pointer;
        border-bottom: 1px solid gray;
        position: sticky;
        top: 0px;
        left: 0px;
        z-index: 9;
        background-color: @bgcolor;
    }

    >.el-aside {
        width: unset !important;
        min-width: fit-content;
        display: block;
        height: 100vh;
        background-color: @bgcolor;
        border-radius: 0px 15px 15px 0px;
        box-shadow: 1px 0px 3px gainsboro;
        overflow-x: hidden;

        &::-webkit-scrollbar {
            width: 1px;
        }

        .el-menu {
            // background-color: @bgcolor;
            border-right-width: 0px;
            width: 100%;

            .el-menu-item {
                height: fit-content;
                background-color: rgba(61, 66, 72, 0.92);
                border-left: 3px solid transparent;

                &:hover {
                    opacity: 0.9;
                    border-left: 3px solid @primary;
                }

                &.is-active {
                    border-color: @primary;
                }
            }
        }
    }
}

#main {
    width: 100%;
    box-sizing: border-box;
    overflow-y: auto;
}

.header {
    box-shadow: 0px 5px 5px gainsboro;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0px;
    z-index: 2001;
    background-color: #fff;
    user-select: none;
    max-width: 100%;

    >div {
        display: flex;
        align-items: center;
        max-width: 80%;
        overflow-x: hidden;
    }
}


.avater-group {
    border: 1px solid rgba(220, 220, 220, 0.648);
    border-radius: 25px;
    padding: 3px 0px;
    cursor: pointer;

    &:hover {
        border-color: rgb(31, 199, 255);
        color: rgb(2, 162, 215);
        background-color: rgba(85, 212, 255, 0.252);
    }

    >* {
        margin: 0 5px;
    }
}
</style>
<!-- 适配移动端 -->
<style lang="less" scoped>
@media screen and (max-width: 768px) {

    .hide {
        display: none !important;
        border: 3px salmon solid;
    }

    .tag-menu {
        display: none;
    }
}
</style>